import { MouseEvent, useState } from 'react';
import { useDispatch } from 'src/store/store';
import { showSignupModal } from 'src/modules/shared/modal/actions';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import i18n from 'src/utils/translate';
import Form from 'src/modules/home/visitor/components/form';
import { FormButton } from 'src/modules/home/visitor/components/sharedStyles';
export default function Join({
  isSmall
}: {
  isSmall?: boolean;
}) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const onSubmit = (e: MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    trackFootfallEvent(Event.Clicked, {
      clickMetadata: {
        section: Section.Join
      },
      clickType: 'visitor-homepage'
    });
    dispatch(showSignupModal({
      defaultEmail: email
    }));
  };
  return <Form isSmall={isSmall} onSubmit={onSubmit} data-sentry-element="Form" data-sentry-component="Join" data-sentry-source-file="joinForm.tsx">
      <input aria-label="Please enter your email address" className="form-control" placeholder={i18n.t('email@address.com')} type="email" value={email} onChange={e => setEmail(e.target.value)} />
      <FormButton isSubmit={true} size="lg" data-sentry-element="FormButton" data-sentry-source-file="joinForm.tsx">{i18n.t('Join')}</FormButton>
    </Form>;
}