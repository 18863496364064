import styled from 'styled-components';
import Button from 'src/components/buttons/button';
import { titleXlarge } from 'styles-js/mixins/typography';

export const H2 = styled.h2`
  ${titleXlarge}
  margin-bottom: 15px;

  @media (${({ theme }) => theme.underScreenMedium}) {
    font-size: ${({ theme }) => theme.fontSizeM};
    line-height: ${({ theme }) => theme.fontLineHeightM};
  }
`;

export const FormButton = styled(Button)`
  padding: 13px 20px 14px;

  @media (${({ theme }) => theme.underScreenSmall}) {
    padding: 6px 12px 7px;
  }
`;
