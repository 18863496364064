// @ts-strict-ignore
import classNames from 'classnames';
import styled from 'styled-components';
import i18n from 'src/utils/translate';
import Section from 'src/modules/home/visitor/components/section';
import { trackFootfallEventWithHrefDelay } from 'src/utils/footfall';
import { Event, Section as FFSection } from 'src/constants/footfall';
import Icon from 'src/components/icon';
import { textLinkPrimary, titleMedium } from 'styles-js/mixins/typography';
import { discoverLinks } from 'src/constants/discoverLinks';
import { H2 } from 'src/modules/home/visitor/components/sharedStyles';
export default function DiscoverSection() {
  function renderLink({
    id,
    href,
    name,
    isMoreLink,
    params
  }) {
    const className = classNames({
      'home__link-more': isMoreLink
    });
    const linkHref = href || `/${id}${params ? params : ''}`;
    return <a className={className} href={linkHref} key={id} onClick={e => {
      e.preventDefault();
      const section = isMoreLink ? FFSection.TagsSeeAll : FFSection.Tag;
      trackFootfallEventWithHrefDelay(linkHref, Event.Clicked, {
        clickMetadata: {
          section,
          type: id
        },
        clickType: 'visitor-homepage'
      });
    }} data-sentry-component="renderLink" data-sentry-source-file="discoverSection.tsx">
        {name}
        {' '}
        {isMoreLink && <Icon icon="open-right" />}
      </a>;
  }
  return <Section data-sentry-element="Section" data-sentry-component="DiscoverSection" data-sentry-source-file="discoverSection.tsx">
      <div className="home__section-content--split">
        <div>
          <H2 data-sentry-element="H2" data-sentry-source-file="discoverSection.tsx">{i18n.t('Discover your health')}</H2>
          <p>{i18n.t("We are leaders in support and information in over 300 different areas of health. Whatever you're looking for, we've got you covered.")}</p>
        </div>
      </div>
      <CommonLinks data-sentry-element="CommonLinks" data-sentry-source-file="discoverSection.tsx">
        {discoverLinks.map(linkSection => {
        const className = classNames({
          'home__link-section': true,
          'home__link-section--large': linkSection.links.length > 14,
          'home__link-section--small': linkSection.links.length < 10
        });
        return <div key={`section-${linkSection.id}`}>
              <h3>{linkSection.title}</h3>
              <div className={className}>
                {linkSection.links.map(renderLink)}
              </div>
            </div>;
      })}
      </CommonLinks>
    </Section>;
}
const CommonLinks = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;

  @media (${({
  theme
}) => theme.underScreenSmall}) {
    margin-top: 0;
  }

  h3 {
    ${titleMedium}
    margin-top: 20px;
  }

  .home__link-section {
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;

    @media (${({
  theme
}) => theme.overScreenSmall}) {
      height: 210px;
    }

    @media (${({
  theme
}) => theme.overScreenMedium}) {
      height: 135px;
    }


    a {
      ${textLinkPrimary}
      line-height: ${({
  theme
}) => theme.fontLineHeightM};

      @media (${({
  theme
}) => theme.overScreenSmall}) {
        width: 33%;
      }
    }

  }
  .home__link-section--large {
    @media (${({
  theme
}) => theme.overScreenSmall}) {
      height: 300px;
    }
  }

  .home__link-section--small {
    @media (${({
  theme
}) => theme.overScreenSmall}) {
      height: 500px;
    }
  }

  .home__link-more {
    margin-top: 20px;
  }
`;