import Router from 'next/router';
import { DefaultLayout } from 'src/components/layouts';
import PressList from 'src/modules/home/visitor/components/pressList';
import DiscoverSection from 'src/modules/home/visitor/components/discoverSection';
import UserQuotesSection from 'src/modules/home/visitor/components/userQuotesSection';
import SearchSection from 'src/modules/home/visitor/components/searchSection';
import Last30DaysSection from 'src/modules/home/visitor/components/last30daysSection';
import { fetchNumbers } from 'src/modules/home/visitor/actions';
import { PageContext } from 'src/constants/types';
import { getLoggedInRedirectUrl } from 'src/utils/context';
import JoinSection from 'src/modules/home/visitor/components/joinSection';
import HomeBanner from 'src/modules/home/visitor/components/homeBanner';
import Features from 'src/modules/home/visitor/components/features';
import Partners from 'src/modules/home/visitor/components/partners';
import HomeVideo from 'src/modules/home/visitor/components/homeVideo';
export default function IndexPage() {
  return <DefaultLayout isFullWidth={true} seo={{
    canonical: 'https://healthunlocked.com'
  }} data-sentry-element="DefaultLayout" data-sentry-component="IndexPage" data-sentry-source-file="index.tsx">
      <HomeBanner data-sentry-element="HomeBanner" data-sentry-source-file="index.tsx" />
      <PressList data-sentry-element="PressList" data-sentry-source-file="index.tsx" />
      <SearchSection data-sentry-element="SearchSection" data-sentry-source-file="index.tsx" />
      <Features data-sentry-element="Features" data-sentry-source-file="index.tsx" />
      <UserQuotesSection data-sentry-element="UserQuotesSection" data-sentry-source-file="index.tsx" />
      <Partners data-sentry-element="Partners" data-sentry-source-file="index.tsx" />
      <HomeVideo data-sentry-element="HomeVideo" data-sentry-source-file="index.tsx" />
      <DiscoverSection data-sentry-element="DiscoverSection" data-sentry-source-file="index.tsx" />
      <Last30DaysSection data-sentry-element="Last30DaysSection" data-sentry-source-file="index.tsx" />
      <JoinSection data-sentry-element="JoinSection" data-sentry-source-file="index.tsx" />
    </DefaultLayout>;
}
IndexPage.getInitialProps = async ({
  asPath,
  res,
  store: {
    dispatch,
    getState
  }
}: PageContext) => {
  if (getState().context.currentUser) {
    // we use asPath because its value includes URL parameters if they are in the URL
    const redirect = getLoggedInRedirectUrl(asPath);
    if (res) {
      res.writeHead(302, {
        Location: redirect
      }).end();
    } else {
      Router.push(redirect);
    }
    return {};
  } else {
    await dispatch(fetchNumbers());
    return {};
  }
};