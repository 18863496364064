import YouTubeEmbed from 'src/components/youTubeEmbed';
import { Video } from 'src/constants/youTube';
import { ResponsiveImage } from 'src/components/responsiveImage';
import Section from 'src/modules/home/visitor/components/section';
import config from 'config/config';
import { Category, useCookieCategory } from 'src/utils/cookie';
export default function HomeVideo() {
  const [isFunctionalityAccepted] = useCookieCategory(Category.Functionality);
  return <Section data-sentry-element="Section" data-sentry-component="HomeVideo" data-sentry-source-file="homeVideo.tsx">
      {isFunctionalityAccepted ? <YouTubeEmbed id={Video.HealthUnlocked} title="HealthUnlocked Video" /> : <a aria-label="Video HealthUnlocked The social network for health on YouTube" href={`https://www.youtube.com/watch?v=${Video.HealthUnlocked}`} rel="noreferrer" target="_blank">
            <ResponsiveImage alt="Video HealthUnlocked The social network for health on YouTube" height={664} src={`${config.cdn.static}img/home/homepage-video-fallback-img-2.jpg`} width={1187} />
          </a>}
    </Section>;
}