import { SyntheticEvent, useState } from 'react';
import { TextLoop } from 'react-text-loop-next';
import styled from 'styled-components';
import Section from 'src/modules/home/visitor/components/section';
import i18n from 'src/utils/translate';
import { performSearch } from 'src/utils/router';
import Form from 'src/modules/home/visitor/components/form';
import { FormButton, H2 } from 'src/modules/home/visitor/components/sharedStyles';
const words = [i18n.t('communities'), i18n.t('others like you'), i18n.t('experiences'), i18n.t('treatments'), i18n.t('answers'), i18n.t('emotional support')];
export default function SearchSection() {
  const [search, setSearch] = useState('');
  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    performSearch(search);
  };
  return <Section withColumns={true} data-sentry-element="Section" data-sentry-component="SearchSection" data-sentry-source-file="searchSection.tsx">
      <StyledH2 data-sentry-element="StyledH2" data-sentry-source-file="searchSection.tsx">
        {i18n.t('Find')}
        {' '}
        <Word data-sentry-element="Word" data-sentry-source-file="searchSection.tsx">
          <TextLoop adjustingSpeed={1000} interval={1500} data-sentry-element="TextLoop" data-sentry-source-file="searchSection.tsx">
            {words.map(word => <span key={word}>{word}</span>)}
          </TextLoop>
        </Word>
      </StyledH2>
      <Form onSubmit={onSubmit} data-sentry-element="Form" data-sentry-source-file="searchSection.tsx">
        <input aria-label="Search on HealthUnlocked" className="form-control home__form-input" placeholder={i18n.t('What are you looking for?')} type="text" value={search} onChange={e => setSearch(e.target.value)} />
        {' '}
        <FormButton size="lg" onClick={onSubmit} data-sentry-element="FormButton" data-sentry-source-file="searchSection.tsx">
          {i18n.t('Search')}
        </FormButton>
      </Form>
    </Section>;
}
const StyledH2 = styled(H2)`
  margin-right: 30px;
`;
const Word = styled.span`
  min-width: 380px;
  display: inline-block;
  color: ${({
  theme
}) => theme.colorBlack};

  @media (${({
  theme
}) => theme.underScreenMedium}) {
    min-width: 220px;
  }
`;