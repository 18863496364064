import styled from 'styled-components';
import Join from 'src/modules/home/visitor/components/joinForm';
import Carousel from 'src/modules/home/visitor/components/carousel';
import { showLoginModal } from 'src/modules/shared/modal/actions';
import { useDispatch } from 'src/store/store';
import i18n from 'src/utils/translate';
import { titleMedium, titleXlarge } from 'styles-js/mixins/typography';
export default function HomeBanner() {
  const dispatch = useDispatch();
  return <Background data-sentry-element="Background" data-sentry-component="HomeBanner" data-sentry-source-file="homeBanner.tsx">
      <Header data-sentry-element="Header" data-sentry-source-file="homeBanner.tsx">
        <Banner data-sentry-element="Banner" data-sentry-source-file="homeBanner.tsx">
          <Content data-sentry-element="Content" data-sentry-source-file="homeBanner.tsx">
            <p className="home__mobile-text">HealthUnlocked</p>
            <h1>{i18n.t(`The world's largest social network for health`)}</h1>
            <p>{i18n.t('Join today. For free, forever.')}</p>
            <Join isSmall={true} data-sentry-element="Join" data-sentry-source-file="homeBanner.tsx" />
            <p>
              {i18n.t('Already a member?')}{' '}
              <a href="#" onClick={e => {
              e.preventDefault();
              dispatch(showLoginModal());
            }}>
                {i18n.t('Log in')}
              </a>
            </p>
          </Content>
        </Banner>
      </Header>
      <Carousel data-sentry-element="Carousel" data-sentry-source-file="homeBanner.tsx" />
    </Background>;
}
const Background = styled.div`
  position: relative;
`;
const Header = styled.div`
  background-color: ${({
  theme
}) => theme.colorBlack};

  p, 
  form {
    margin-bottom: 15px;
  }
`;
const Banner = styled.div`
  position: relative;
  z-index: 1000;
  padding: 90px 15px;
  max-width: 1200px;
  margin: 0 auto;

  @media (${({
  theme
}) => theme.overScreenXlarge}) {
    padding: 130px 15px;
  }

  @media (${({
  theme
}) => theme.underScreenMedium}) {
    padding: 70px 15px;
  }

  @media (${({
  theme
}) => theme.underScreenSmall}) {
    padding: 40px 15px;
    line-height: 24px;
  }
`;
const Content = styled.div`
  max-width: 602px;
  color: ${({
  theme
}) => theme.colorWhite};

  h1 {
    ${titleXlarge};
    font-size: 50px;
    line-height: 58px;
    margin-bottom: 25px;
    color: ${({
  theme
}) => theme.colorWhite};
  }
  
  a {
    color: ${({
  theme
}) => theme.colorWhite};
  }

  a:hover {
    text-decoration: underline;
  }

  p {
    font-size: ${({
  theme
}) => theme.fontSizeM};
  }

  .home__mobile-text {
    ${titleMedium}
    color: ${({
  theme
}) => theme.colorWhite};

    @media (${({
  theme
}) => theme.overScreenSmall}) {
      display: none;
    }
  }
`;