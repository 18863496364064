import Section from 'src/modules/home/visitor/components/section';
import i18n from 'src/utils/translate';
import FeatureList from 'src/modules/home/visitor/components/featureList';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section as FFSection } from 'src/constants/footfall';
import Icon from 'src/components/icon';
import { H2 } from 'src/modules/home/visitor/components/sharedStyles';
export default function Features() {
  return <Section data-sentry-element="Section" data-sentry-component="Features" data-sentry-source-file="features.tsx">
      <H2 data-sentry-element="H2" data-sentry-source-file="features.tsx">{i18n.t('A holistic approach to healthcare')}</H2>
      <p>
        {i18n.t('Get information, support and tools that have been shown to improve health outcomes.')}{' '}
        <a href="https://about.healthunlocked.com/validation" rel="noreferrer" target="_blank" onClick={() => {
        trackFootfallEvent(Event.Clicked, {
          clickMetadata: {
            section: FFSection.SeeResearch
          },
          clickType: 'visitor-homepage'
        });
      }}>
          {i18n.t('Learn about our research')} <Icon icon="open-right" data-sentry-element="Icon" data-sentry-source-file="features.tsx" />
        </a>
      </p>
      <FeatureList data-sentry-element="FeatureList" data-sentry-source-file="features.tsx" />
    </Section>;
}