/* eslint-disable @next/next/no-img-element */
import styled from 'styled-components';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
export default function Press({
  pressPath,
  id,
  pressUrl
}: {
  pressPath: string;
  id: string;
  pressUrl: string;
}) {
  return <Wrapper data-sentry-element="Wrapper" data-sentry-component="Press" data-sentry-source-file="press.tsx">
      <a href={pressUrl} rel="noreferrer" target="_blank" onClick={() => {
      trackFootfallEvent(Event.Clicked, {
        clickMetadata: {
          section: Section.PressLinks
        },
        clickType: 'visitor-homepage'
      });
    }}>
        <img alt={id} src={pressPath} />
      </a>
    </Wrapper>;
}
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 190px;

  img {
    padding: 20px;
    width: 100%;
  }
`;