import Icon from 'src/components/icon';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section as FFSection } from 'src/constants/footfall';
import i18n from 'src/utils/translate';
import PartnerLogos from 'src/modules/home/visitor/components/partnerLogos';
import Section from 'src/modules/home/visitor/components/section';
import { H2 } from 'src/modules/home/visitor/components/sharedStyles';
export default function Partners() {
  return <Section data-sentry-element="Section" data-sentry-component="Partners" data-sentry-source-file="partners.tsx">
      <H2 data-sentry-element="H2" data-sentry-source-file="partners.tsx">{i18n.t('Our partners')}</H2>
      <p>
        {i18n.t("Over 600 organizations have used our technology to build health communities. It's the best available and it's free to nonprofits, health advocates, and patient organizations.")}{' '}
        <a href="https://about.healthunlocked.com/start-a-community" rel="noreferrer" target="_blank" onClick={() => {
        trackFootfallEvent(Event.Clicked, {
          clickMetadata: {
            section: FFSection.PartnersLearnMore
          },
          clickType: 'visitor-homepage'
        });
      }}>
          {i18n.t('Start a community')} <Icon icon="open-right" data-sentry-element="Icon" data-sentry-source-file="partners.tsx" />
        </a>
      </p>
      <PartnerLogos data-sentry-element="PartnerLogos" data-sentry-source-file="partners.tsx" />
    </Section>;
}